<template>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <span>活动名称:</span>
      <el-input
        v-model="option.activityName"
        clearable
        placeholder="请输入活动名称"
      ></el-input>
      <span>活动类型:</span>
      <el-input
        v-model="option.activityType"
        clearable
        placeholder="请输入活动类型"
      ></el-input>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
          >查 询</el-button
        >
        <el-button
          icon="el-icon-document-add"
          @click="AddClick()"
          type="primary"
          >新建活动</el-button
        >
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <!--隐藏列-->
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      >
      </el-table-column>
      <el-table-column prop="id" label="ID" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="activityName" label="活动名称" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="enabled" label="是否启用">
        <template v-slot:default="scope">
          <span v-if="scope.row.enabled === 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column prop="eventStart" label="活动开始时间" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="eventEnd" label="活动结束时间" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="activityType" label="活动类型">
        <template #default="scope">
          <span v-if="scope.row.activityType == 'NineSquareGrid'">九宫格</span>
          <span v-else>{{ scope.row.activityType }}</span>
        </template>
      </el-table-column>
      <!--<el-table-column label="背景图片" prop="eventBg" align="center" width="100px">
                <template v-slot="scope">
                    <el-image @click="bigImg(scope.row.eventBg)" style="width: 50px; height: 50px; margin-bottom:-4px"
                              :src=scope.row.eventBg
                              :preview-src-list="data.srcList">
                    </el-image>
                    <el-popover placement="top-start" trigger="click"> &lt;!&ndash;trigger属性值：hover、click、focus 和 manual&ndash;&gt;
                        <a :href="scope.row.eventBg" target="_blank" title="查看最大化图片">
                            <img :src="scope.row.eventBg" style="width: 300px;height: 300px">
                        </a>
                        <img :src="scope.row.eventBg" style="width: 50px;height: 50px; cursor:pointer">
                    </el-popover>
                </template>
            </el-table-column>-->
      <el-table-column prop="eventBg" label="九宫格区域背景颜色" v-if="false">
      </el-table-column>
      <el-table-column prop="eventRules" label="活动规则描述" v-if="false">
      </el-table-column>
      <el-table-column
        prop="winningInformation"
        label="中奖信息是否显示"
        v-if="false"
      >
        <template v-slot:default="scope">
          <span v-if="scope.row.winningInformation === 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="virtualWinningInformation"
        label="虚拟中奖信息是否显示"
        v-if="false"
      >
        <template v-slot:default="scope">
          <span v-if="scope.row.virtualWinningInformation === 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="winningTips"
        label="中奖后是否显示中奖提示"
        v-if="false"
      >
        <template v-slot:default="scope">
          <span v-if="scope.row.winningTips === 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="winningTipsContent"
        label="中奖提示内容"
        v-if="false"
      >
      </el-table-column>

      <!--规则设置-->
      <el-table-column prop="maxDrawNumber" label="最多抽奖次数" v-if="false">
      </el-table-column>
      <el-table-column
        prop="dayMaxDrawNumber"
        label="每天最多抽奖次数"
        v-if="false"
      >
      </el-table-column>
      <el-table-column
        prop="lotteryConsumesPoints"
        label="每次抽奖消耗积分"
        v-if="false"
      >
      </el-table-column>
      <el-table-column
        prop="personMaxDrawNumber"
        label="每人最多抽奖次数"
        v-if="false"
      >
      </el-table-column>
      <el-table-column
        prop="dayPersonMaxDrawNumber"
        label="每人每天最多抽奖次数"
        v-if="false"
      >
      </el-table-column>

      <!--样式设置-->
      <!--<el-table-column prop="themeColor" label="主题颜色" v-if="false"> </el-table-column>-->
      <el-table-column
        label="背景图片"
        prop="themeColor"
        v-if="false"
        align="center"
        width="100px"
      >
        <template v-slot="scope">
          <el-image
            @click="bigImg(scope.row.themeColor)"
            style="width: 50px; height: 50px; margin-bottom: -4px"
            :src="scope.row.themeColor"
            :preview-src-list="data.srcList"
          >
          </el-image>
          <el-popover placement="top-start" trigger="click">
            <!--trigger属性值：hover、click、focus 和 manual-->
            <a
              :href="scope.row.themeColor"
              target="_blank"
              title="查看最大化图片"
            >
              <img
                :src="scope.row.themeColor"
                style="width: 300px; height: 300px"
              />
            </a>
            <img
              :src="scope.row.themeColor"
              style="width: 50px; height: 50px; cursor: pointer"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="eventMusic" label="活动音乐" v-if="false">
      </el-table-column>
      <el-table-column
        prop="opportunityDisplay"
        label="抽奖机会是否显示"
        v-if="false"
      >
        <template v-slot:default="scope">
          <span v-if="scope.row.opportunityDisplay === 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column prop="commonColors" label="公共字体颜色" v-if="false">
      </el-table-column>
      <el-table-column prop="prizeColor" label="抽中奖品背景颜色" v-if="false">
      </el-table-column>
      <el-table-column prop="awardColors" label="奖品名称字体颜色" v-if="false">
      </el-table-column>
      <el-table-column
        prop="lotteryColors"
        label="点击抽奖背景颜色"
        v-if="false"
      >
      </el-table-column>
      <el-table-column
        prop="characterColor"
        label="点击抽奖字体颜色"
        v-if="false"
      >
      </el-table-column>
      <el-table-column prop="allColors" label="奖品背景颜色" v-if="false">
      </el-table-column>

      <!--参与人设置-->
      <el-table-column prop="participants" label="参与人（会员等级）" show-overflow-tooltip>
      </el-table-column>

      <!--分享配置-->
      <el-table-column prop="shareTitle" label="分享标题" v-if="false">
      </el-table-column>
      <el-table-column prop="shareDescription" label="分享描述" v-if="false">
      </el-table-column>
      <el-table-column
        label="分享图片"
        prop="shareImage"
        v-if="false"
        align="center"
        width="100px"
      >
        <template v-slot="scope">
          <el-image
            @click="bigImg(scope.row.shareImage)"
            style="width: 50px; height: 50px; margin-bottom: -4px"
            :src="scope.row.shareImage"
            :preview-src-list="data.srcList"
          >
          </el-image>
          <el-popover placement="top-start" trigger="click">
            <!--trigger属性值：hover、click、focus 和 manual-->
            <a
              :href="scope.row.shareImage"
              target="_blank"
              title="查看最大化图片"
            >
              <img
                :src="scope.row.shareImage"
                style="width: 300px; height: 300px"
              />
            </a>
            <img
              :src="scope.row.shareImage"
              style="width: 50px; height: 50px; cursor: pointer"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="dayPersonSharesNumber"
        label="每人每天最多分享次数"
        v-if="false"
      >
      </el-table-column>
      <el-table-column
        prop="additionalDraws"
        label="分享后获得抽奖次数"
        v-if="false"
      >
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="400">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button size="small" icon="el-icon-edit" @click="UpdateClick(scope.row)" type="primary">编辑</el-button>
          <el-popconfirm
            title="您确定要删除吗？"
            confirm-button-text="是"
            cancel-button-text="否"
            iconColor="red"
            @confirm="deleteClick(scope.row)"
          >
            <template #reference>
              <el-button size="small" icon="el-icon-delete" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
          <el-button @click="ViewPrizeconfig(scope.row)" type="primary" size="small">奖品配置</el-button>
          <el-button @click="ViewsPrizeconfig(scope.row)" type="primary" size="small">抽奖记录</el-button>
          <!--<el-button @click="ViewDescImg(scope.row)" type="primary" size="small">详情</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="8"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>
    <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog top="3vh" width="80%" title="抽奖活动" v-model="ruleForm.dialogFormVisible" :show-close="true" @close="resetForm('ruleForm')">
    <div style="max-height: 80vh;overflow-y: auto;">
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
        <div style="margin-bottom: 15px;background-color: rgb(242, 242, 242);height: 50px;">
          <h4 style="font-size: 18px;margin-bottom: 10px;padding-top: 12px;padding-left: 20px;">活动信息</h4>
        </div>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="活动名称"
              required
              prop="activityName"
              label-width="170px"
            >
              <el-input
                v-model.trim="ruleForm.activityName"
                clearable
                placeholder="请输入活动名称"
                :maxlength="30"
                autocomplete="off"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="是否启用"
              prop="enabled"
              required
              label-width="170px"
            >
              <el-select
                v-model="ruleForm.enabled"
                placeholder="请选择是否启用"
                :disabled="!ruleForm.id"
              >
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              label="活动开始时间"
              prop="eventStart"
              required
              label-width="170px"
            >
              <el-date-picker
                v-model="ruleForm.eventStart"
                type="datetime"
                placeholder="选择开始日期"
                :default-value="new Date()"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="活动结束时间"
              prop="eventEnd"
              required
              label-width="170px"
            >
              <el-date-picker
                v-model="ruleForm.eventEnd"
                type="datetime"
                placeholder="选择结束日期"
                :default-value="new Date()"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="11">
            <el-form-item
              label="活动类型"
              prop="activityType"
              required
              label-width="170px"
            >
              <el-radio-group
                v-model="ruleForm.activityType"
                style="margin-left: 20px"
              >
                <el-row>
                  <el-col :span="24">
                    <el-radio label="NineSquareGrid" style="margin-bottom: 10px"
                      >九宫格</el-radio
                    >
                  </el-col>
                </el-row>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-row style="margin-bottom: 20px">
            <el-col :span="11">
              <!--<el-form-item label="活动音乐"  required prop="eventMusic" label-width="170px">
                                    <el-input v-model="ruleForm.eventMusic" clearable  placeholder="请选择活动音乐" autocomplete="off" style="width: 220px"></el-input>
                                </el-form-item>-->

              <el-form-item
                label="活动音乐"
                prop="eventMusic"
                label-width="170px"
              >
                <el-upload
                  ref="myUpload"
                  action=""
                  :file-list="ruleForm.fileListmp3"
                  :on-preview="handlePreviewmp3"
                  :on-remove="handleRemovemp3"
                  :before-upload="beforeUploadmp3"
                  :http-request="handleUploadmp3"
                  :multiple="false"
                  :limit="1"
                  accept=".mp3"
                >
                  <el-button size="small" type="primary"
                    >上传音乐文件</el-button
                  >
                </el-upload>
                <div v-if="ruleForm.imageDivmp3">
                  <!--<el-button @click="musicClose" style="position:absolute; margin-left:-40px;" type="danger" icon="el-icon-close" circle></el-button>-->
                  <el-button @click="musicClose" type="danger" size="mini"
                    >清空音乐</el-button
                  >
                </div>
                <el-col>
                  <p style="color: red; padding-bottom: 20px; width: 350px">
                    * 音乐文件格式 大小不超过 5MB；扩展名：".mp3"
                  </p>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>

        <el-row style="margin-bottom: 20px">
          <el-form-item label="背景图片" prop="themeColor" label-width="170px">
            <el-upload
              ref="myupload"
              action=""
              :file-list="ruleForm.fileList"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :beforeUpload="beforeUpload"
              :http-request="handleUpload"
              :multiple="false"
              :limit="1"
              accept=".png,.jpg"
            >
              <el-button size="small" type="primary">上传背景图片</el-button>
            </el-upload>
            <el-col>
              <div style="color: red;margin-bottom: 10px;">* 图片格式 宽：1024px；高：1880px；大小不超过2MB；扩展名：".png,.jpg,.jpeg"</div>
            </el-col>
            <!--看上传的图片-->
            <div v-if="ruleForm.imageDiv">
              <el-image :src="ruleForm.themeColor" fit="cover" :preview-src-list="[ruleForm.themeColor]" style="width: 150px; height: 150px"></el-image>
              <el-button @click="imageClose" style="position: absolute;margin-left: -15px;margin-top: -10px;" type="danger" icon="el-icon-close" circle size="mini"></el-button>
              <div style="font-size: 14px;color: #999;line-height: 18px;">点击图片可预览大图</div>
            </div>
          </el-form-item>
        </el-row>

        <el-row style="margin-bottom: 20px">
          <el-form-item label="我的页面背景图片" prop="activityImage" label-width="170px">
            <el-upload
              ref="myupload"
              action=""
              :file-list="ruleForm.activityImageFileList"
              :on-preview="handlePreviewMyBg"
              :on-remove="handleRemoveMyBg"
              :beforeUpload="beforeUpload"
              :http-request="handleMyUpload"
              :multiple="false"
              :limit="1"
              accept=".png,.jpg,.jpeg"
            >
              <el-button size="small" type="primary">上传图片</el-button>
            </el-upload>
            <el-col>
              <div style="color: red;line-height: 25px;">* 图片格式 宽：322px，高：83px（如果尺寸较小不清晰，宽、高可以等比例放大，比如宽2535，高654）</div>
              <div style="color: red;line-height: 25px;margin-bottom: 15px;">* 图片大小不超过2MB；扩展名：".png，.jpg，.jpeg"</div>
            </el-col>
            <div v-if="ruleForm.activityImageDiv">
              <el-image :src="ruleForm.activityImage" fit="cover" :preview-src-list="[ruleForm.activityImage]" style="width: 150px; height: 150px"></el-image>
              <el-button @click="imageCloseMyBg" style="position: absolute;margin-left: -15px;margin-top: -10px;" type="danger" icon="el-icon-close" circle size="mini"></el-button>
              <div style="font-size: 14px;color: #999;line-height: 18px;">点击图片可预览大图</div>
            </div>
          </el-form-item>
        </el-row>

        <el-row style="margin-bottom: 20px">
          <el-form-item label="抽奖页跳转过渡图片" prop="activityLoadingImage" label-width="170px">
            <el-upload
              ref="myupload"
              action=""
              :file-list="ruleForm.activityLoadingImageFileList"
              :on-preview="handlePreviewLoadingBg"
              :on-remove="handleRemoveLoadingBg"
              :beforeUpload="beforeUpload"
              :http-request="handleLoadingUpload"
              :multiple="false"
              :limit="1"
              accept=".png,.jpg,.jpeg"
            >
              <el-button size="small" type="primary">上传图片</el-button>
            </el-upload>
            <el-col>
              <div style="color: red;margin-bottom: 15px;">* 图片格式 宽：1024px；高：1880px；大小不超过2MB；扩展名：".png，.jpg，.jpeg"</div>
            </el-col>
            <div v-if="ruleForm.activityLoadingImageDiv">
              <el-image :src="ruleForm.activityLoadingImage" fit="cover" :preview-src-list="[ruleForm.activityLoadingImage]" style="width: 150px; height: 150px"></el-image>
              <el-button @click="imageCloseLoadingImage" style="position: absolute;margin-left: -15px;margin-top: -10px;" type="danger" icon="el-icon-close" circle size="mini"></el-button>
              <div style="font-size: 14px;color: #999;line-height: 18px;">点击图片可预览大图</div>
            </div>
          </el-form-item>
        </el-row>

        <el-row>
          <el-col :span="16">
            <el-form-item
              label="活动规则描述"
              prop="eventRules"
              label-width="170px"
            >
              <!--<el-input v-model="ruleForm.productDetails"/>-->
              <el-input
                v-model="ruleForm.eventRules"
                type="textarea"
                :rows="6"
                clearable
                placeholder="活动规则描述"
                autocomplete="off"
                :maxlength="500"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="11">
            <el-form-item
              label="中奖信息是否显示"
              prop="winningInformation"
              required
              label-width="170px"
            >
              <el-select
                v-model="ruleForm.winningInformation"
                placeholder="请选择中奖信息是否显示"
              >
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="11">
            <el-form-item
              label="虚拟中奖信息是否显示"
              prop="virtualWinningInformation"
              required
              label-width="170px"
            >
              <el-select
                v-model="ruleForm.virtualWinningInformation"
                placeholder="请选择虚拟中奖信息是否显示"
              >
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="11">
            <el-form-item
              label="是否显示中奖提示"
              prop="winningTips"
              required
              label-width="170px"
            >
              <el-select
                v-model="ruleForm.winningTips"
                placeholder="请选择是否显示中奖提示"
              >
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="11">
            <el-form-item
              label="否显示抽奖机会"
              prop="opportunityDisplay"
              required
              label-width="170px"
            >
              <el-select
                v-model="ruleForm.opportunityDisplay"
                placeholder="请选择是否显示抽奖机会"
              >
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!--<el-row style="margin-top: 10px" v-if="ruleForm.winningTips === 1">
                        <el-col :span="16">
                            <el-form-item label="中奖提示内容" prop="winningTipsContent" label-width="170px">
                                <el-input
                                        v-model="ruleForm.winningTipsContent"
                                        type="textarea"
                                        :rows="6"
                                        clearable
                                        placeholder="中奖提示内容"
                                        autocomplete="off"
                                        :maxlength="500"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>-->
        <div
          style="
            margin-bottom: 15px;
            background-color: rgb(242, 242, 242);
            height: 50px;
          "
        >
          <h4
            style="
              font-size: 18px;
              margin-bottom: 10px;
              padding-top: 12px;
              padding-left: 20px;
            "
          >
            规则设置
          </h4>
          <!--<p style="font-size: 14px; color: #666;">这里是您想要添加的二级标题的描述或其他信息。</p>-->
        </div>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="最多抽奖次数"
              prop="maxDrawNumber"
              label-width="170px"
            >
              <el-input
                v-model="ruleForm.maxDrawNumber"
                clearable
                placeholder="不填写则不限制抽奖次数"
                :maxlength="8"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                autocomplete="off"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="每天最多抽奖次数"
              prop="dayMaxDrawNumber"
              label-width="170px"
            >
              <el-input
                v-model="ruleForm.dayMaxDrawNumber"
                clearable
                placeholder="不填写则不限制抽奖次数"
                :maxlength="8"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                autocomplete="off"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="每人最多抽奖次数"
              required
              prop="personMaxDrawNumber"
              label-width="170px"
            >
              <el-input
                v-model="ruleForm.personMaxDrawNumber"
                clearable
                placeholder="请输入次数"
                :maxlength="8"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                autocomplete="off"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="每人每天最多抽奖次数"
              required
              prop="dayPersonMaxDrawNumber"
              label-width="170px"
            >
              <el-input
                v-model="ruleForm.dayPersonMaxDrawNumber"
                clearable
                placeholder="请输入次数"
                :maxlength="8"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                autocomplete="off"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="每次抽奖消耗积分"
              required
              prop="lotteryConsumesPoints"
              label-width="170px"
            >
              <el-input
                v-model="ruleForm.lotteryConsumesPoints"
                clearable
                placeholder="填写0则表示不消耗积分"
                :maxlength="8"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                autocomplete="off"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="每人每天初始抽奖次数"
              prop="dayPersonInitDrawNumber"
              label-width="170px"
            >
              <el-input
                v-model="ruleForm.dayPersonInitDrawNumber"
                clearable
                placeholder="请输入次数"
                :maxlength="8"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                autocomplete="off"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="满足单日累计消费金额"
              prop="singleConsumeAmt"
              label-width="170px"
            >
              <el-input
                v-model="ruleForm.singleConsumeAmt"
                clearable
                placeholder="请输入单日累计消费金额"
                :maxlength="11"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                autocomplete="off"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="满足金额获得的抽奖次数"
              prop="singleConsumeDraws"
              label-width="170px"
            >
              <el-input
                v-model="ruleForm.singleConsumeDraws"
                clearable
                placeholder="请输入次数"
                :maxlength="8"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                autocomplete="off"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="
            margin-bottom: 15px;
            background-color: rgb(242, 242, 242);
            height: 50px;
          "
        >
          <h4
            style="
              font-size: 18px;
              margin-bottom: 10px;
              padding-top: 12px;
              padding-left: 20px;
            "
          >
            样式设置
          </h4>
          <!--<p style="font-size: 14px; color: #666;">这里是您想要添加的二级标题的描述或其他信息。</p>-->
        </div>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="九宫格区域背景颜色"
              required
              prop="eventBg"
              label-width="170px"
            >
              <el-color-picker
                v-model="ruleForm.eventBg"
                show-alpha
                format="hex"
                size="small"
              ></el-color-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="公共字体颜色"
              required
              prop="commonColors"
              label-width="170px"
            >
              <el-color-picker
                v-model="ruleForm.commonColors"
                show-alpha
                format="hex"
                size="small"
              ></el-color-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="抽中奖品背景颜色"
              required
              prop="prizeColor"
              label-width="170px"
            >
              <el-color-picker
                v-model="ruleForm.prizeColor"
                show-alpha
                format="hex"
                size="small"
              ></el-color-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="点击抽奖背景颜色"
              required
              prop="lotteryColors"
              label-width="170px"
            >
              <el-color-picker
                v-model="ruleForm.lotteryColors"
                show-alpha
                format="hex"
                size="small"
              ></el-color-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="点击抽奖字体颜色"
              required
              prop="characterColor"
              label-width="170px"
            >
              <el-color-picker
                v-model="ruleForm.characterColor"
                show-alpha
                format="hex"
                size="small"
              ></el-color-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="点击抽奖后抽奖按钮背景颜色"
              required
              prop="inTheLotteryColors"
              label-width="220px"
            >
              <el-color-picker
                v-model="ruleForm.inTheLotteryColors"
                show-alpha
                format="hex"
                size="small"
              ></el-color-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="奖品名称字体颜色"
              required
              prop="awardColors"
              label-width="170px"
            >
              <el-color-picker
                v-model="ruleForm.awardColors"
                show-alpha
                format="hex"
                size="small"
              ></el-color-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              label="奖品背景颜色"
              required
              prop="allColors"
              label-width="170px"
            >
              <el-color-picker
                v-model="ruleForm.allColors"
                show-alpha
                format="hex"
                size="small"
              ></el-color-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="
            margin-bottom: 15px;
            background-color: rgb(242, 242, 242);
            height: 50px;
          "
        >
          <h4
            style="
              font-size: 18px;
              margin-bottom: 10px;
              padding-top: 12px;
              padding-left: 20px;
            "
          >
            参与人设置
          </h4>
          <!--<p style="font-size: 14px; color: #666;">这里是您想要添加的二级标题的描述或其他信息。</p>-->
        </div>
        <el-row style="margin-bottom: 20px">
          <el-col :span="11">
            <el-form-item
              label="参与人（会员等级）:"
              required
              prop="participants"
              label-width="170px"
            >
              <el-select
                v-model="ruleForm.participants"
                placeholder="请选择参与人会员等级"
                ref="vipEquitySelect"
                style="width: 220px"
                multiple
              >
                <el-option
                  v-for="item in data.VipListData"
                  :key="item.id"
                  :label="item.vipName"
                  :value="item.vipName"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="
            margin-bottom: 15px;
            background-color: rgb(242, 242, 242);
            height: 50px;
          "
        >
          <h4
            style="
              font-size: 18px;
              margin-bottom: 10px;
              padding-top: 12px;
              padding-left: 20px;
            "
          >
            分享配置
          </h4>
          <!--<p style="font-size: 14px; color: #666;">这里是您想要添加的二级标题的描述或其他信息。</p>-->
        </div>
        <el-form-item
          label="是否允许分享"
          prop="isShowSharePage"
          label-width="auto"
        >
          <el-radio-group v-model="ruleForm.isShowSharePage">
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-show="ruleForm.isShowSharePage">
          <el-row style="margin-bottom: 20px">
            <el-col :span="11">
              <el-form-item
                label="分享标题"
                prop="shareTitle"
                label-width="170px"
                :required="ruleForm.isShowSharePage"
              >
                <el-input
                  v-model.trim="ruleForm.shareTitle"
                  clearable
                  placeholder="请输入分享标题"
                  :maxlength="50"
                  autocomplete="off"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 20px">
            <el-form-item
              label="分享图片"
              prop="shareImage"
              label-width="170px"
            >
              <el-upload
                ref="myupload"
                action=""
                :file-list="ruleForm.fileLists"
                :on-preview="handlePreviews"
                :on-remove="handleRemoves"
                :beforeUpload="beforeUploads"
                :http-request="handleUploads"
                :multiple="false"
                :limit="1"
                accept=".png,.jpg"
              >
                <el-button size="small" type="primary">上传分享图片</el-button>
              </el-upload>
              <el-col>
                <p style="color: red; padding-bottom: 20px">
                  * 图片格式 宽：300px；高：300px；大小不超过
                  2MB；扩展名：".png,.jpg,.jpeg"
                </p>
              </el-col>
              <!--看上传的图片-->
              <div v-if="ruleForm.imageDivs">
                <el-image
                  :src="ruleForm.shareImage"
                  fit="contain"
                  style="width: 500px"
                ></el-image>
                <el-button
                  @click="imagesClose"
                  style="position: absolute; margin-left: -40px"
                  type="danger"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
            </el-form-item>
          </el-row>
          <el-row style="margin-bottom: 20px">
            <el-col :span="16">
              <el-form-item
                label="分享描述"
                prop="shareDescription"
                label-width="170px"
              >
                <el-input
                  v-model="ruleForm.shareDescription"
                  type="textarea"
                  :rows="6"
                  clearable
                  placeholder="分享描述"
                  autocomplete="off"
                  :maxlength="500"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 20px">
            <el-col :span="11">
              <el-form-item
                label="每人每天最多分享次数"
                prop="dayPersonSharesNumber"
                label-width="170px"
                :required="ruleForm.isShowSharePage"
              >
                <el-input
                  v-model.number="ruleForm.dayPersonSharesNumber"
                  clearable
                  placeholder="请输入每人每天最多分享次数"
                  :maxlength="8"
                  oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                  autocomplete="off"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item
                label="分享后获得抽奖次数"
                prop="additionalDraws"
                label-width="170px"
                :required="ruleForm.isShowSharePage"
              >
                <el-input
                  v-model.number="ruleForm.additionalDraws"
                  clearable
                  placeholder="请输入分享后获得抽奖次数"
                  :maxlength="8"
                  oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
                  autocomplete="off"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<!-- 本页的js -->
<script>
import { onMounted, reactive, ref, nextTick } from "vue";
import {
  getLotteryRuleList,
  updateLotteryRule,
  addLotteryRule,
  delLotteryRule,
  getLotteryRuleById,
  getvip,
} from "../http/api";
import { ElMessage } from "element-plus";
import { FullTimeFormat } from "../utils/common";
import { putToOSS, getFileNameUUID, getFileExtensions } from "@/http/oss";
import { useRouter } from "vue-router";
import { getequity } from "../http/vipmanage";
export default {
  components: {},
  methods: {
    formatCreationTime(row) {
      return FullTimeFormat(row.creationTime);
    },
  },
  name: "LotteryRuleConfig",
  setup() {
    const ruleFormRef = ref(null); //表单不能用this.$,取一个重名的
    const router = useRouter();
    //声明数据table表格的内容
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      VipListData: [],
      srcList: [""],
    });
    //声明查询条件
    let option = reactive({
      activityName: "",
      page: 1,
      pageSize: 10,
    });
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };
    //删除事件
    let deleteClick = async (obj) => {
      let data = { id: obj.id };
      let delResult = await delLotteryRule(data);
      queryBannerListFun(option);
      if (delResult) {
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      } else {
        ElMessage.error({
          message: "删除失败！",
          duration: 2000,
        });
      }
    };
    // 我的页面抽奖背景图片
    let imageCloseMyBg = () => {
      ruleForm.activityImage = "";
      ruleForm.activityImageDiv = false;
    };
    // 抽奖过渡图片
    let imageCloseLoadingImage = () => {
      ruleForm.activityLoadingImage = "";
      ruleForm.activityLoadingImageDiv = false;
    };

    //删除上传的图片
    let imageClose = () => {
      ruleForm.themeColor = "";
      ruleForm.imageDiv = false;
    };
    let imagesClose = () => {
      ruleForm.shareImage = "";
      ruleForm.imageDivs = false;
    };
    let musicClose = () => {
      ruleForm.eventMusic = "";
      ruleForm.imageDivmp3 = false;
    };

    let queryBannerListFun = async (obj) => {
      let arr = await getLotteryRuleList(obj);
      //格式化日期
      arr.items.filter((item) => {
        item.eventStart = FullTimeFormat(item.eventStart);
        item.eventEnd = FullTimeFormat(item.eventEnd);
        item.isActive = item.isActive == true ? "是" : "否";
      });
      data.total = arr.totalCount;
      data.table = arr.items;
    };
    //初始化时，获取初始化数据
    queryBannerListFun({ Page: 1, PageSize: 10 });
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      queryBannerListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      await queryBannerListFun(option);
    };
    let getVipListDataFun = async (obj) => {
      let vipList = await getvip(obj);
      data.VipListData = vipList;
    };
    getVipListDataFun({});
    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      activityName: "",
      enabled: 1, //(0否，1是)
      eventStart: "",
      eventEnd: "",
      activityType: "NineSquareGrid",
      eventBg: "",
      eventRules: "",
      winningInformation: 1, //(0否，1是)
      virtualWinningInformation: 1, //(0否，1是)
      winningTips: 1, //(0否，1是)
      winningTipsContent: "",
      maxDrawNumber: null,
      dayMaxDrawNumber: null,
      lotteryConsumesPoints: null,
      personMaxDrawNumber: null,
      dayPersonMaxDrawNumber: null,
      themeColor: "",
      activityImage: "", // 我的页面背景图片
      activityImageDiv: false,
      activityImageFileList: [],
      activityLoadingImage: "", // 过度图片
      activityLoadingImageDiv: false,
      activityLoadingImageFileList: [],
      imageDiv: false,
      imageDivs: false,
      imageDivmp3: false,
      eventMusic: "",
      participants: [],
      shareTitle: "",
      shareDescription: "",
      shareImage: "",
      dayPersonSharesNumber: null,
      additionalDraws: null,
      addOrUpdate: 0, //0新增，1更新
      opportunityDisplay: 1, //(0否，1是)
      commonColors: "",
      prizeColor: "",
      lotteryColors: "",
      characterColor: "",
      inTheLotteryColors: "", // 点击抽奖后抽奖按钮背景颜色
      awardColors: "",
      allColors: "",
      dayPersonInitDrawNumber: null,
      singleConsumeAmt: null,
      singleConsumeDraws: null,
      isShowSharePage: false,
    });
    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleFormRef.value?.resetFields();

      ruleForm.addOrUpdate = 1;
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let banner = await getLotteryRuleById({ id: obj.id }); //未做
      ruleForm.activityName = banner.activityName;
      ruleForm.enabled = banner.enabled;
      ruleForm.eventStart = banner.eventStart;
      ruleForm.eventEnd = banner.eventEnd;
      ruleForm.activityType = banner.activityType;
      ruleForm.eventBg = banner.eventBg;
      ruleForm.eventRules = banner.eventRules;
      ruleForm.winningInformation = banner.winningInformation;
      ruleForm.virtualWinningInformation = banner.virtualWinningInformation;
      ruleForm.winningTips = banner.winningTips;
      ruleForm.winningTipsContent = banner.winningTipsContent;
      ruleForm.maxDrawNumber = banner.maxDrawNumber;
      ruleForm.dayMaxDrawNumber = banner.dayMaxDrawNumber;
      ruleForm.lotteryConsumesPoints = banner.lotteryConsumesPoints;
      ruleForm.personMaxDrawNumber = banner.personMaxDrawNumber;
      ruleForm.dayPersonMaxDrawNumber = banner.dayPersonMaxDrawNumber;
      ruleForm.themeColor = banner.themeColor;
      ruleForm.eventMusic = banner.eventMusic;
      ruleForm.participants = banner.participants.split(",");
      ruleForm.shareTitle = banner.shareTitle;
      ruleForm.shareDescription = banner.shareDescription;
      ruleForm.shareImage = banner.shareImage;
      ruleForm.dayPersonSharesNumber = banner.dayPersonSharesNumber;
      ruleForm.additionalDraws = banner.additionalDraws;
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleForm.imageDivmp3 = banner.eventMusic && banner.eventMusic.trim() !== "";
      ruleForm.imageDiv = banner.themeColor && banner.themeColor.trim() !== "";
      // 过渡图片 我的页面抽奖背景
      ruleForm.activityImage = banner.activityImage;
      ruleForm.activityImageDiv = banner.activityImage && banner.activityImage.trim() != null;
      ruleForm.activityImageFileList = []
      ruleForm.activityLoadingImage = banner.activityLoadingImage;
      ruleForm.activityLoadingImageDiv = banner.activityLoadingImage && banner.activityLoadingImage.trim() != null;
      ruleForm.activityLoadingImageFileList = []

      ruleForm.imageDivs = banner.shareImage && banner.shareImage.trim() !== "";
      ruleForm.commonColors = banner.commonColors;
      ruleForm.prizeColor = banner.prizeColor;
      ruleForm.lotteryColors = banner.lotteryColors;
      ruleForm.characterColor = banner.characterColor;
      ruleForm.opportunityDisplay = banner.opportunityDisplay;
      ruleForm.inTheLotteryColors = banner.inTheLotteryColors;
      ruleForm.awardColors = banner.awardColors;
      ruleForm.allColors = banner.allColors;

      ruleForm.dayPersonInitDrawNumber = banner.dayPersonInitDrawNumber;
      ruleForm.singleConsumeAmt = banner.singleConsumeAmt;
      ruleForm.singleConsumeDraws = banner.singleConsumeDraws;
      ruleForm.isShowSharePage = banner.isShowSharePage;
    };
    //点击新增按钮
    let AddClick = async () => {
      ruleFormRef.value?.resetFields();
      ruleForm.addOrUpdate = 0;
      ruleForm.id = null;
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleForm.activityName = "";
      ruleForm.enabled = 0; // 初始为禁用状态，完成默认奖项设置后，手动启用
      ruleForm.eventStart = "";
      ruleForm.eventEnd = "";
      ruleForm.activityType = "NineSquareGrid";
      ruleForm.eventBg = "";
      ruleForm.eventRules = "";
      ruleForm.winningInformation = 1;
      ruleForm.virtualWinningInformation = 1;
      ruleForm.winningTips = 1;
      ruleForm.winningTipsContent = "";
      ruleForm.maxDrawNumber = null;
      ruleForm.dayMaxDrawNumber = null;
      ruleForm.lotteryConsumesPoints = null;
      ruleForm.personMaxDrawNumber = null;
      ruleForm.dayPersonMaxDrawNumber = null;
      ruleForm.themeColor = "";
      // 过渡图片 我的页面抽奖背景
      ruleForm.activityImage = "";
      ruleForm.activityImageDiv = false;
      ruleForm.activityImageFileList = [];
      ruleForm.activityLoadingImage = "";
      ruleForm.activityLoadingImageDiv = false;
      ruleForm.activityLoadingImageFileList = [];

      ruleForm.eventMusic = "";
      ruleForm.participants = [];
      ruleForm.shareTitle = "";
      ruleForm.shareDescription = "";
      ruleForm.shareImage = "";
      ruleForm.dayPersonSharesNumber = null;
      ruleForm.additionalDraws = null;
      ruleForm.commonColors = "";
      ruleForm.prizeColor = "";
      ruleForm.lotteryColors = "";
      ruleForm.characterColor = "";
      ruleForm.inTheLotteryColors = "";
      ruleForm.awardColors = "";
      ruleForm.allColors = "";
      ruleForm.opportunityDisplay = 1;
      //清空照片和文件
      ruleForm.imageDiv = false;
      ruleForm.imageDivs = false;
      ruleForm.imageDivmp3 = false;
      ruleForm.fileList = [];
      ruleForm.fileLists = [];
      ruleForm.fileListmp3 = [];
      ruleForm.dayPersonInitDrawNumber = null;
      ruleForm.singleConsumeAmt = null;
      ruleForm.singleConsumeDraws = null;
      ruleForm.isShowSharePage = false;
    };
    //修改的表单验证
    let rules = reactive({
      activityName: [{ required: true, message: "请输入活动名称" }],
      enabled: [{ required: true, message: "请选择是否启用" }],
      eventStart: [{ required: true, message: "请选择活动开始时间" }],
      eventEnd: [{ required: true, message: "请选择活动结束时间" }],
      activityType: [{ required: true, message: "请选择活动活动类型" }],
      eventBg: [{ required: true, message: "请选择背景颜色" }],
      /* eventRules: [{ required: true, message: "请输入活动规则描述" }],*/
      winningInformation: [
        { required: true, message: "请选择中奖信息是否显示" },
      ],
      virtualWinningInformation: [
        { required: true, message: "请选择虚拟中奖信息是否显示" },
      ],
      winningTips: [
        { required: true, message: "请选择中奖后是否显示中奖提示" },
      ],
      /*winningTipsContent: [{ required: true, message: "请输入中奖提示内容" }],*/
      maxDrawNumber: [{ required: false, message: "请输入最多抽奖次数" }],
      dayMaxDrawNumber: [
        { required: false, message: "请输入每天最多抽奖次数" },
      ],
      personMaxDrawNumber: [
        { required: true, message: "请输入每人最多抽奖次数" },
      ],
      dayPersonMaxDrawNumber: [
        { required: true, message: "请输入每人每天最多抽奖次数" },
      ],
      lotteryConsumesPoints: [
        { required: true, message: "请输入每次抽奖消耗积分" },
      ],
      dayPersonInitDrawNumber: {
        required: true,
        message: "请输入每人每天初始抽奖次数",
      },
      isShowSharePage: { required: true, message: "请选择是否允许分享" },
      themeColor: [{ required: true, message: "请上传背景图片" }],
      activityImage: [{ required: true, message: "请上传我的页面背景图片" }],
      activityLoadingImage: [{ required: true, message: "请上传抽奖过渡图片" }],
      
      /*eventMusic: [{ required: true, message: "请选择活动音乐" }],*/
      participants: [{ required: true, message: "请输入参与人（会员等级）" }],
      shareTitle: {
        validator: (rule, value, callback) => {
          if (ruleForm.isShowSharePage && !value) {
            callback(new Error("请输入分享标题"));
          } else {
            callback();
          }
        },
      },
      /*shareDescription: [{ required: true, message: "请输入分享描述" }],*/
      /*shareImage: [{ required: true, message: "请上传分享图片" }],*/
      dayPersonSharesNumber: {
        validator: (rule, value, callback) => {
          if (ruleForm.isShowSharePage && (value === "" || value == null)) {
            callback(new Error("请输入每人每天最多分享次数"));
          } else if (ruleForm.isShowSharePage && !(value > 0)) {
            callback(new Error("次数须大于0"));
          } else {
            callback();
          }
        },
      },
      additionalDraws: {
        validator: (rule, value, callback) => {
          if (ruleForm.isShowSharePage && (value === "" || value == null)) {
            callback(new Error("请输入分享后获得抽奖次数"));
          } else {
            callback();
          }
        },
      },
      commonColors: [{ required: true, message: "请选择公共字体颜色" }],
      prizeColor: [{ required: true, message: "请选择抽中奖品背景颜色" }],
      allColors: [{ required: true, message: "请选择奖品背景颜色" }],
      inTheLotteryColors: [
        { required: true, message: "请选择抽奖后抽奖按钮背景颜色" },
      ],
      awardColors: [{ required: true, message: "请选择奖品名称字体颜色" }],
      lotteryColors: [{ required: true, message: "请选择点击抽奖背景颜色" }],
      characterColor: [{ required: true, message: "请选择抽奖字体颜色" }],
      opportunityDisplay: [
        { required: true, message: "请选择抽奖机会是否显示" },
      ],
    });
    //提交表单,新增活修改抽奖信息
    let submitForm = async (formName) => {
      // console.log("====submit form: ", ruleForm);
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          return false;
        }
      });
      if (con) {
        return false;
      }
      let participantsString = ruleForm.participants.join(","); // 示例：将数组转换为逗号分隔的字符串
      try {
        if (ruleForm.addOrUpdate == 1) {
          //更新
          let data = {
            id: ruleForm.id,
            activityName: ruleForm.activityName,
            enabled: ruleForm.enabled,
            eventStart: ruleForm.eventStart,
            eventEnd: ruleForm.eventEnd,
            activityType: ruleForm.activityType,
            eventBg: ruleForm.eventBg,
            eventRules: ruleForm.eventRules,
            winningInformation: ruleForm.winningInformation,
            virtualWinningInformation: ruleForm.virtualWinningInformation,
            winningTips: ruleForm.winningTips,
            winningTipsContent: ruleForm.winningTipsContent,
            maxDrawNumber: ruleForm.maxDrawNumber,
            dayMaxDrawNumber: ruleForm.dayMaxDrawNumber,
            lotteryConsumesPoints: ruleForm.lotteryConsumesPoints,
            personMaxDrawNumber: ruleForm.personMaxDrawNumber,
            dayPersonMaxDrawNumber: ruleForm.dayPersonMaxDrawNumber,
            themeColor: ruleForm.themeColor,
            // 过渡图片 我的页面抽奖背景
            activityImage: ruleForm.activityImage,
            activityLoadingImage: ruleForm.activityLoadingImage,

            eventMusic: ruleForm.eventMusic,
            participants: participantsString,
            shareTitle: ruleForm.shareTitle,
            shareDescription: ruleForm.shareDescription,
            shareImage: ruleForm.shareImage,
            dayPersonSharesNumber: ruleForm.dayPersonSharesNumber,
            additionalDraws: ruleForm.additionalDraws,
            commonColors: ruleForm.commonColors,
            prizeColor: ruleForm.prizeColor,
            lotteryColors: ruleForm.lotteryColors,
            opportunityDisplay: ruleForm.opportunityDisplay,
            characterColor: ruleForm.characterColor,
            inTheLotteryColors: ruleForm.inTheLotteryColors,
            awardColors: ruleForm.awardColors,
            allColors: ruleForm.allColors,
            dayPersonInitDrawNumber: ruleForm.dayPersonInitDrawNumber,
            singleConsumeAmt: ruleForm.singleConsumeAmt,
            singleConsumeDraws: ruleForm.singleConsumeDraws,
            isShowSharePage: ruleForm.isShowSharePage,
          };
          let resitem = await updateLotteryRule(data);
          if (resitem.code == 200) {
            ElMessage.success({
              message: resitem.message,
              duration: 2000,
              type: "success",
            });
            await queryBannerListFun(option);
            ruleForm.dialogFormVisible = false;
          } else {
            ElMessage.error({
              message: resitem.message,
              duration: 2000,
            });
          }
        } else {
          //新增
          let data = {
            activityName: ruleForm.activityName,
            enabled: ruleForm.enabled,
            eventStart: ruleForm.eventStart,
            eventEnd: ruleForm.eventEnd,
            activityType: ruleForm.activityType,
            eventBg: ruleForm.eventBg,
            eventRules: ruleForm.eventRules,
            winningInformation: ruleForm.winningInformation,
            virtualWinningInformation: ruleForm.virtualWinningInformation,
            winningTips: ruleForm.winningTips,
            winningTipsContent: ruleForm.winningTipsContent,
            maxDrawNumber: ruleForm.maxDrawNumber,
            dayMaxDrawNumber: ruleForm.dayMaxDrawNumber,
            lotteryConsumesPoints: ruleForm.lotteryConsumesPoints,
            personMaxDrawNumber: ruleForm.personMaxDrawNumber,
            dayPersonMaxDrawNumber: ruleForm.dayPersonMaxDrawNumber,
            themeColor: ruleForm.themeColor,
            // 过渡图片 我的页面抽奖背景
            activityImage: ruleForm.activityImage,
            activityLoadingImage: ruleForm.activityLoadingImage,
            
            eventMusic: ruleForm.eventMusic,
            participants: participantsString,
            shareTitle: ruleForm.shareTitle,
            shareDescription: ruleForm.shareDescription,
            shareImage: ruleForm.shareImage,
            dayPersonSharesNumber: ruleForm.dayPersonSharesNumber,
            additionalDraws: ruleForm.additionalDraws,
            commonColors: ruleForm.commonColors,
            prizeColor: ruleForm.prizeColor,
            lotteryColors: ruleForm.lotteryColors,
            opportunityDisplay: ruleForm.opportunityDisplay,
            characterColor: ruleForm.characterColor,
            inTheLotteryColors: ruleForm.inTheLotteryColors,
            awardColors: ruleForm.awardColors,
            allColors: ruleForm.allColors,
            dayPersonInitDrawNumber: ruleForm.dayPersonInitDrawNumber,
            singleConsumeAmt: ruleForm.singleConsumeAmt,
            singleConsumeDraws: ruleForm.singleConsumeDraws,
            isShowSharePage: ruleForm.isShowSharePage,
          };
          let resitems = await addLotteryRule(data);
          if (resitems.code == 200) {
            ElMessage.success({
              message: resitems.message,
              duration: 2000,
              type: "success",
            });
            await queryBannerListFun(option);
            ruleForm.dialogFormVisible = false;
          } else {
            ElMessage.error({
              message: resitems.message,
              duration: 2000,
            });
          }
        }
      } catch (error) {
        ElMessage.error({
          message: "操作失败，请重试！", // 可以根据实际情况修改失败消息
          duration: 2000,
        });
        console.error(error); // 输出错误信息到控制台，方便调试
      }
    };
    //表单重置
    let resetForm = (formName) => {
      ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };
    //验证采用弹窗的方式,没做
    let btnSubmit = () => {};
    //大图
    let bigImg = (url) => {
      data.srcList[0] = url;
    };

    let ViewPrizeconfig = (obj) => {
      router.push({ name: "Prizeconfig", query: { id: obj.id } });
      //router.push({ path: `/GoodsDescImg/${obj.id}` });
    };
    let ViewsPrizeconfig = (obj) => {
      router.push({ name: "Drawrecords", query: { id: obj.id } });
      //router.push({ path: `/GoodsDescImg/${obj.id}` });
    };
    onMounted(async () => {
      // 在onMounted生命周期钩子中调用GetAllListAsync方法
    });

    let beforeUpload = (file) => {
      // 限制上传类型
      const fileExtensions =
        getFileExtensions(file.name) === ".jpg" ||
        getFileExtensions(file.name) === ".png" ||
        getFileExtensions(file.name) === ".jpeg";
      //限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000,
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000,
        });
      }
      return fileExtensions && max2M;
    };
    let beforeUploads = (file) => {
      // 限制上传类型
      const fileExtensions =
        getFileExtensions(file.name) === ".jpg" ||
        getFileExtensions(file.name) === ".png" ||
        getFileExtensions(file.name) === ".jpeg";
      //限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000,
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000,
        });
      }
      return fileExtensions && max2M;
    };
    let beforeUploadmp3 = (file) => {
      // 限制上传类型
      const fileExtensions = getFileExtensions(file.name) === ".mp3";
      //限制的上限为5M
      const max5M = file.size / 1024 / 1024 < 5;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .mp3 格式!",
          duration: 2000,
        });
      }
      if (!max5M) {
        ElMessage.error({
          message: "上传文件大小不能超过 5MB!",
          duration: 2000,
        });
      }
      return fileExtensions && max5M;
    };


    // 我的页面背景图片
    let handleMyUpload = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        ruleForm.activityImage = res.url;
        ruleForm.activityImageDiv = true;
      });
    };
    // 过渡图片
    let handleLoadingUpload = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        ruleForm.activityLoadingImage = res.url;
        ruleForm.activityLoadingImageDiv = true;
      });
    };

    let handleUpload = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName =
        "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        //console.log(res,'res')
        // 上传成功之后，转换真实的地址
        //signatureUrl(`flieName/${objName}`).then(res => {
        //console.log(res)
        ruleForm.themeColor = res.url;
        ruleForm.imageDiv = true;
        //})
      });
    };
    let handleUploads = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName =
        "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        //console.log(res,'res')
        // 上传成功之后，转换真实的地址
        //signatureUrl(`flieName/${objName}`).then(res => {
        //console.log(res)
        ruleForm.shareImage = res.url;
        ruleForm.imageDivs = true;
        //})
      });
    };
    let handleUploadmp3 = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName =
        "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        //console.log(res,'res')
        // 上传成功之后，转换真实的地址
        //signatureUrl(`flieName/${objName}`).then(res => {
        //console.log(res)
        ruleForm.eventMusic = res.url;
        ruleForm.imageDivmp3 = true;
        //})
      });
    };

    let submitUpload = () => {
      this.$refs.upload.submit();
    };
    let handleRemove = (file, fileList) => {
      console.log(file, fileList);
    };
    let handleRemoves = (file, fileLists) => {
      console.log(file, fileLists);
    };
    let handleRemovemp3 = (file, fileListmp3) => {
      console.log(file, fileListmp3);
      this.ruleForm.fileListmp3 = [];
    };
    let handlePreview = (file) => {
      console.log(file);
    };
    let handlePreviews = (file) => {
      console.log(file);
    };
    let handlePreviewmp3 = (file) => {
      console.log(file);
    };

    let handlePreviewMyBg = (file) => {
      console.log(file);
    };
    let handleRemoveMyBg = (file, fileList) => {
      ruleForm.activityImageFileList = [];
    };
    let handlePreviewLoadingBg = (file) => {
      console.log(file);
    };
    let handleRemoveLoadingBg = (file, fileList) => {
      ruleForm.activityLoadingImageFileList = [];
    };

    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      UpdateClick,
      ruleForm,
      ruleFormRef,
      rules,
      submitForm,
      resetForm,
      AddClick,
      deleteClick,
      imageClose,
      imagesClose,
      musicClose,
      getFileExtensions,
      beforeUpload,
      beforeUploads,
      beforeUploadmp3,
      handleUpload,
      handleUploads,
      handleUploadmp3,
      submitUpload,
      handleRemove,
      handleRemoves,
      handleRemovemp3,
      handlePreview,
      handlePreviews,
      handlePreviewmp3,
      bigImg,
      indexMethod,
      ViewPrizeconfig,
      ViewsPrizeconfig,

      handlePreviewMyBg,
      handleRemoveMyBg,
      handleMyUpload,
      imageCloseMyBg,
      handlePreviewLoadingBg,
      handleRemoveLoadingBg,
      handleLoadingUpload,
      imageCloseLoadingImage
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.box {
}
.top {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}
.el-image-viewer__close {
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0) !important;
}
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 50px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
